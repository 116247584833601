<template>
  <div class="orderWrap">
    <div class="orderModule">
      <div class="orderTopModule">
        <div class="orderImage">
          <img :src="orderImageUrl" class="imageDer" alt="" />
        </div>
        <div class="orderData">
          <div class="orderDataTop">
            <div class="orderDataTitle">{{tripName}}</div>
            <div class="orderDataPrice">{{prize}}</div>
          </div>
          <div class="orderType">
            <div class="orderTypeName">规格:</div>
            <div class="orderTypeNameTwo">{{gg}}</div>
          </div>
        </div>
      </div>
      <div class="orderNumberModule">
        <div class="orderNumberLabel">购买数量:</div>
        <div class="orderNumberCtrol">
          <div class="sub" @click="subOrderNumber">-</div>
          <div class="number">{{ orderNumber }}</div>
          <div class="add" @click="addOrderNumber()">+</div>
        </div>
      </div>
      <div class="redText">每个订单最多可购买4份</div>
    </div>
    <div class="payModule">
      <div class="allMoneryLable">总计:</div>
      <div class="allMonery">
        <div class="allMoneryNumber">{{ allMoneryNumber }}</div>
      </div>
      <div class="payBtn" @click="order">立即购买</div>
    </div>
  </div>
</template>
<script>
import ynTripList from '../../utils/ynShopFree.js'
export default {
  name: "",
  props: {},
  data() {
    return {
      orderImageUrl: "",
      orderNumber: 1,
      bizId: "",
      subLimit: true,
      wxBackResult: "",
      tripList:ynTripList,
      prize:0,
      tripName:'',
      gg:'',
      productNo:''
    };
  },
  components: {},
  computed: {
    allMoneryNumber() {
      return (this.prize * this.orderNumber).toFixed(2);
    },
  },
  created() {
    this.getTripData();
  },
  mounted() {
  },
  beforeMount() {},
  beforeDestroy() {},
  methods: {
    getTripData(){
      let selShopItem = localStorage.getItem('selShopItem')
      console.log(selShopItem)
      console.log(JSON.parse(selShopItem))
      const {name,prize,typeName,smallImages,productNo} = JSON.parse(selShopItem);
      this.tripName = name;
      this.prize = prize;
      this.gg = typeName;
      this.orderImageUrl = smallImages;
      this.productNo = productNo;
    },

    changeUnit(fen) {
      var num = fen;
      num = fen * 0.01;
      num += "";
      var reg =
        num.indexOf(".") > -1
          ? /(\d{1,3})(?=(?:\d{3})+\.)/g
          : /(\d{1,3})(?=(?:\d{3})+$)/g;
      num = num.replace(reg, "$1");
      num = this.delDot(num);
      return num;
    },
    addOrderNumber() {
      if (this.orderNumber > 3) {
        this.$toast({
          message: "超出最大份数",
          position: "center",
        });
      } else {
        this.orderNumber++;
      }
    },
    subOrderNumber() {
      if (this.orderNumber < 2) {
        this.$toast({
          message: "不能减小",
          position: "center",
        });
      } else {
        this.orderNumber--;
      }
    },
    order() {
      this.request
        .post("/api/n/trip/placeOrder", {
          bizId: this.timeFormat(),
          productNo:this.productNo,
          buyCount: this.orderNumber,
        })
        .then((res) => {
          console.log(res);
          if (res) {
            const { bizId ,status} = res;
            this.bizId = bizId;
            if(status ==1) {
                this.$router.push({
                path: "/h/n/ynTripPayCheck",
                query: { bizId: this.bizId },
            });

            }else {
             this.$toast({
                message: "订购失败，请稍候重试",
                position: "bottom",
              });
            }

          }
        });
    },
   
    timeFormat() {
      let time = new Date();
      let year = time.getFullYear();
      let oldMouth = time.getMonth() + 1;
      console.log(oldMouth);
      let mouth = oldMouth > 9 ? oldMouth : "0" + oldMouth;
      let day = time.getDate() > 9 ? time.getDate() : "0" + time.getDate();
      let hours = time.getHours() > 9 ? time.getHours() : "0" + time.getHours();
      let min =
        time.getMinutes() > 9 ? time.getMinutes() : "0" + time.getMinutes();
      let second =
        time.getSeconds() > 9 ? time.getSeconds() : "0" + time.getSeconds();
      let minSecond =
        time.getMilliseconds() > 9
          ? time.getMilliseconds()
          : "0" + time.getMilliseconds();
      console.log(
        String(year) +
          String(mouth) +
          String(day) +
          String(hours) +
          String(min) +
          String(second) +
          String(minSecond)
      );
      let timeStr =
        String(year) +
        String(mouth) +
        String(day) +
        String(hours) +
        String(min) +
        String(second) +
        String(minSecond);
      let randomNumber = Math.ceil(Math.random() * 1000000000000000);
      let oldBizId = String(timeStr) + String(randomNumber);
      console.log(oldBizId.length);
      let bizId = oldBizId.slice(0, 32);
      return bizId;
    },
  
  },
};
</script>
<style scoped>
.orderWrap {
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  background: #f8f8f8;
  overflow: hidden;
}
.orderModule {
  width: 6.9rem;
  height: 3rem;
  border: 0.1rem;
  padding: 0.23rem;
  box-sizing: border-box;
  background: #ffffff;
  margin: 0 auto;
  margin-top: 0.45rem;
}
.orderTopModule {
  height: 1.49rem;
  display: flex;
  display: -webkit-flex;
}
.imageDer{
  display: block;
  width: 100%;
  height: 100%;
}
.orderImage {
  width: 1.49rem;
  height: 1.49rem;
  border-radius: 0.05rem;
  background: #dadada;
  overflow: hidden;
}
.orderData {
  margin-left: 0.3rem;
  height: 1.49rem;
}
.orderDataTop {
  width: 4.55rem;
  height: 1rem;
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  /* align-items: center; */
  padding-top: 0.15rem;
  box-sizing: border-box;
}
.orderType {
  width: 4.55rem;
  height: 0.49rem;
  font-size: 0.22rem;
  color: #999999;
  display: flex;
  display: -webkit-flex;
}
.orderDataTitle {
  width: 3.2rem;
  font-size: 0.28rem;
  color: #333333;
}
.orderDataPrice::before {
  content: "￥";
  color: #e14040;
  font-size: 0.28rem;
}
.orderDataPrice {
  font-weight: bolder;
  font-size: 0.36rem;
  color: #e14040;
}
.orderTypeName {
  width: 0.6rem;
  font-size: 0.22rem;
  color: #999999;
}
.orderTypeNameTwo{
  width: 3.5rem;
  font-size: 0.22rem;
  color: #999999;
}
.orderNumberModule {
  height: 0.35rem;
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.4rem;
  margin-bottom: 0.15rem;
}
.orderNumberLabel {
  font-size: 0.22rem;
  color: #333333;
  font-weight: bolder;
}
.orderNumberCtrol {
  width: 1.25rem;
  height: 0.35rem;
  border: 0.01rem solid #dcdcdc;
  border-radius: 0.05rem;
  font-size: 0.22rem;
  color: #333333;
  font-weight: bolder;
  display: flex;
  display: -webkit-flex;
}
.sub {
  width: 0.35rem;
  height: 0.35rem;
  text-align: center;
  line-height: 0.35rem;
  box-sizing: border-box;
  border-right: 0.01rem solid #dbdbdb;
}
.number {
  width: 0.55rem;
  height: 0.35rem;
  text-align: center;
  line-height: 0.35rem;
  box-sizing: border-box;
  border-right: 0.01rem solid #dbdbdb;
}
.add {
  width: 0.35rem;
  height: 0.35rem;
  text-align: center;
  line-height: 0.35rem;
  box-sizing: border-box;
}
.redText {
  margin-top: 0.15rem;
  text-align: right;
  font-size: 0.22rem;
  color: #e14040;
}
.payModule {
  width: 7.5rem;
  height: 1.2rem;
  background: #ffffff;
  padding: 0 0.3rem;
  box-sizing: border-box;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  display: -webkit-flex;
  justify-content: flex-end;
  overflow: hidden;
}
.allMoneryLable {
  font-size: 0.26rem;
  color: #333333;
  font-weight: bolder;
  margin-top: 0.55rem;
}
.allMonery {
  min-width: 1.7rem;
  /* display: flex;
    display: -webkit-flex; */
  text-align: center;
  font-size: 0.32rem;
  color: #e14040;
  margin-top: 0.55rem;
}
.allMoneryNumber {
  font-size: 0.32rem;
  color: #e14040;
  font-weight: bolder;
}
.allMoneryNumber::before {
  content: "￥";
  font-size: 0.24rem;
  color: #e14040;
  font-weight: bolder;
}
.payBtn {
  width: 2.2rem;
  height: 0.7rem;
  color: #f8f8f8;
  font-size: 0.32rem;
  text-align: center;
  line-height: 0.7rem;
  background-image: linear-gradient(to right, #e9402d, #e74e44);
  border-radius: 0.35rem;
  margin: 0.25rem 0;
}
.payMethod {
  width: 7.5rem;
  height: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 2;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  justify-content: flex-end;
  background: rgba(0, 0, 0, 0.4);
}
.payMethodModule {
  width: 7.5rem;
  border-top-left-radius: 0.15rem;
  border-top-right-radius: 0.15rem;
  background: #f3f3f3;
  padding-bottom: 0.85rem;
}
.payModole {
  width: 6.9rem;
  margin: 0.4rem 0.3rem;
}
.title {
  text-align: center;
  font-size: 0.36rem;
  color: #595959;
  font-weight: bolder;
  position: relative;
  margin-bottom: 0.7rem;
}
.fork {
  width: 0.3rem;
  height: 0.3rem;
  background: url(~@/assets/ynTrip/fork.png) no-repeat;
  background-size: 100% 100%;
  position: absolute;
  left: 0;
  top: -0.05rem;
  z-index: 1;
}
.methodWay {
  height: 1.5rem;
  border-radius: 0.15rem;
  background: #fff;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 0.4rem;
  padding-right: 0.32rem;
  box-sizing: border-box;
  margin-bottom: 0.4rem;
}
.methodLeft {
  height: 1.5rem;
  display: flex;
  display: -webkit-flex;
  align-items: center;
}
.wxIcon {
  width: 0.61rem;
  height: 0.54rem;
  background: url(~@/assets/ynTrip/wx.png) no-repeat;
  background-size: 100% 100%;
}
.payName {
  font-size: 0.32rem;
  color: #333333;
  font-weight: bolder;
  margin-left: 0.35rem;
}
.payGo {
  width: 0.16rem;
  height: 0.28rem;
  background: url(~@/assets/ynTrip/payGo.png) no-repeat;
  background-size: 100% 100%;
}
.zfbIcon {
  width: 0.61rem;
  height: 0.54rem;
  background: url(~@/assets/ynTrip/zfb.png) no-repeat;
  background-size: 100% 100%;
}
</style>
